<template lang="pug">
  .drop-off-fee-form
    ArrowBack(@back="handleClickBack")
    .title
      span {{ $t("company_system.basic_settings.drop_off_fees.details") }}
    .form
      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.drop_off_fees.setting_unit')"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-field.fee-types
          AppDropdown(
            close-on-select
            :class="{ invalid: $v.dropOffFee.setting_unit.$error }"
            value-key="unit"
            title-key="name"
            :value="selectedSettingUnit"
            :items="settingUnits"
            @select="selectSettingUnit"
          )
      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.drop_off_fees.departure_prefecture')"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-field.fee-types
          AppDropdown(
            close-on-select
            :class="{ invalid: $v.dropOffFee.departure_prefecture_id.$error }"
            :value="selectedDeparturePrefecture"
            :items="translatedPrefectures"
            @select="selectDeparturePrefecture"
          )
      .form-row(v-if="isShopSettingUnitSelected")
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.drop_off_fees.departure_shop')"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-field.fee-types
          AppDropdown(
            close-on-select
            :class="{ invalid: $v.dropOffFee.departure_shop_id.$error }"
            :value="selectedDepartureShop"
            :items="shops"
            @select="selectDepartureShop"
          )
      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.drop_off_fees.transfer_prefecture')"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-field.fee-types
          AppDropdown(
            close-on-select
            :value="selectedDestinationPrefecture"
            :class="{ invalid: $v.dropOffFee.destination_prefecture_id.$error }"
            :items="translatedPrefectures"
            @select="selectDestinationPrefecture"
          )
      .form-row(v-if="isShopSettingUnitSelected")
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.drop_off_fees.transfer_shop')"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-field.fee-types
          AppDropdown(
            close-on-select
            :class="{ invalid: $v.dropOffFee.destination_shop_id.$error }"
            :value="selectedDestinationShop"
            :items="shops"
            @select="selectDestinationShop"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.drop_off_fees.car_type')")
        .form-row-field.fee-types
          AppDropdown(
            multiple
            allow-empty
            removable-labels
            close-on-select
            :value="selectedCarTypes"
            :items="translatedCarTypes"
            @select="selectCarTypes"
          )
      .form-row
        .form-row-label
          FormFieldLabel(
            :title="$t('company_system.basic_settings.drop_off_fees.fee')"
            required
            :required-label="$t('company_system.req')"
          )
        .form-row-field.fee.flex-row
          AppNumberInput(
            use-delimiter
            name="fee"
            :min="0"
            :max="999999"
            :class="{ invalid: $v.dropOffFee.fee.$error }"
            v-model="dropOffFee.fee"
          )
          .label
            span {{ $t('company_system.currency') }}
    FormActionBar(
      :delete-enabled="isEdit"
      @delete="removeDropOffFee"
      @save="handleSave"
    )
</template>

<script>
  // misc
  import { find, filter, map, cloneDeep, isEqual } from "lodash-es"
  import { PREFECTURE_SETTING_UNIT, SHOP_SETTING_UNIT } from "./constants"
  import { defaultDropOffFeeObject } from "./helpers"
  import { extractTranslatedAttribute } from "@/helpers/common"

  // store modules
  import dropOffFeesModule from "@/config/store/company_system/basic_settings/drop_off_fees"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withScrollTop from "@/mixins/withScrollTop"
  import withCompanySystemFormMethods from "@/mixins/company_system/withFormMethods"

  const validationsMixin = withValidations(({ required, requiredIf, integer, minValue }) => ({
    dropOffFee: {
      setting_unit: { required },
      departure_prefecture_id: { required },
      departure_shop_id: {
        required: requiredIf(function(nested) {
          return nested.setting_unit === SHOP_SETTING_UNIT
        })
      },
      destination_prefecture_id: { required },
      destination_shop_id: {
        required: requiredIf(function(nested) {
          return nested.setting_unit === SHOP_SETTING_UNIT
        })
      },
      fee: {
        required,
        minValue: minValue(0),
        integer
      }
    }
  }))

  const dropOffFeesMixin = withStoreModule(dropOffFeesModule, {
    name: "dropOffFees",
    readers: {
      dropOffFeeItem: "item",
      dropOffFeeLoading: "loading"
    },
    actions: {
      fetchDropOffFee: "FETCH_ITEM",
      createDropOffFee: "CREATE_ITEM",
      updateDropOffFee: "UPDATE_ITEM",
      deleteDropOffFee: "DELETE_ITEM"
    },
    mutations: { setDirty: "SET_DIRTY" }
  })

  export default {
    components: {
      ArrowBack: () => import("@/components/CompanySystem/ArrowBack"),
      FormActionBar: () => import("../../FormActionBar"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    mixins: [dropOffFeesMixin, validationsMixin, withScrollTop, withCompanySystemFormMethods],

    data() {
      return {
        dropOffFee: defaultDropOffFeeObject(),
        initialDropOffFee: {}
      }
    },

    props: {
      dropOffFeeId: {
        type: Number,
        required: false
      },
      prefectures: {
        type: Array,
        default: () => new Array()
      },
      shops: {
        type: Array,
        default: () => new Array()
      },
      carTypes: {
        type: Array,
        default: () => new Array()
      }
    },

    mounted() {
      if (this.isEdit) {
        this.fetchDropOffFee(this.dropOffFeeId).then(() => {
          this.dropOffFee = cloneDeep(this.dropOffFeeItem)
          this.initialDropOffFee = cloneDeep(this.dropOffFeeItem)
        })
      } else {
        this.initialDropOffFee = defaultDropOffFeeObject()
      }
    },

    methods: {
      selectSettingUnit({ unit }) {
        this.dropOffFee.setting_unit = unit
        if (unit === PREFECTURE_SETTING_UNIT) {
          this.dropOffFee.departure_shop_id = null
          this.dropOffFee.destination_shop_id = null
        }
      },

      selectDeparturePrefecture({ id }) {
        this.dropOffFee.departure_prefecture_id = id
      },

      selectDepartureShop({ id }) {
        this.dropOffFee.departure_shop_id = id
      },

      selectDestinationPrefecture({ id }) {
        this.dropOffFee.destination_prefecture_id = id
      },

      selectDestinationShop({ id }) {
        this.dropOffFee.destination_shop_id = id
      },

      selectCarTypes(carTypes) {
        this.dropOffFee.companies_car_type_ids = map(carTypes, "id")
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.dropOffFee = cloneDeep(this.dropOffFeeItem)
          this.initialDropOffFee = cloneDeep(this.dropOffFeeItem)
        })
      },

      async sendRequest() {
        if (this.isEdit) {
          await this.updateDropOffFee(this.dropOffFee)
        } else {
          await this.createDropOffFee(this.dropOffFee)
        }
      },

      removeDropOffFee() {
        this.$confirm({
          title: this.$t("company_system.basic_settings.delete_drop_off_fee_confirmation"),
          resolve: {
            handler: () => {
              this.deleteDropOffFee(this.dropOffFee.id).then(() => {
                this.$emit("back")
              })
            }
          }
        })
      }
    },

    computed: {
      isEdit() {
        return !!(this.dropOffFeeId || this.dropOffFee.id)
      },

      hasUnsavedChanges() {
        return !isEqual(this.dropOffFee, this.initialDropOffFee)
      },

      isShopSettingUnitSelected() {
        return this.dropOffFee.setting_unit === SHOP_SETTING_UNIT
      },

      selectedSettingUnit({ dropOffFee: { setting_unit } }) {
        return setting_unit && find(this.settingUnits, { unit: setting_unit })
      },

      selectedDeparturePrefecture({ dropOffFee: { departure_prefecture_id } }) {
        return departure_prefecture_id && find(this.translatedPrefectures, { id: departure_prefecture_id })
      },

      selectedDepartureShop({ dropOffFee: { departure_shop_id } }) {
        return departure_shop_id && find(this.shops, { id: departure_shop_id })
      },

      selectedDestinationPrefecture({ dropOffFee: { destination_prefecture_id } }) {
        return destination_prefecture_id && find(this.translatedPrefectures, { id: destination_prefecture_id })
      },

      selectedDestinationShop({ dropOffFee: { destination_shop_id } }) {
        return destination_shop_id && find(this.shops, { id: destination_shop_id })
      },

      selectedCarTypes({ dropOffFee: { companies_car_type_ids } }) {
        return filter(this.translatedCarTypes, ({ id }) => companies_car_type_ids.includes(id))
      },

      translatedPrefectures() {
        return map(this.prefectures, prefecture => {
          return {
            id: prefecture.id,
            name: extractTranslatedAttribute(prefecture, "name")
          }
        })
      },

      translatedCarTypes() {
        return map(this.carTypes, carType => {
          return {
            id: carType.id,
            name: extractTranslatedAttribute(carType, "type_name")
          }
        })
      },

      settingUnits() {
        return [
          {
            name: this.$t("company_system.basic_settings.drop_off_fees.prefecture"),
            unit: PREFECTURE_SETTING_UNIT
          },
          {
            name: this.$t("company_system.basic_settings.drop_off_fees.shop"),
            unit: SHOP_SETTING_UNIT
          }
        ]
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"

  .drop-off-fee-form
    +styled-inputs
    input
      height: 38px

    margin-bottom: 20px

    .flex-row
      display: flex
      justify-content: left

    .title
      margin-top: 20px
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          ::v-deep
            .app-number-input
              &.invalid
                input
                  +default-invalid-input

          .app-select
            padding: 0

          &.fee
            ::v-deep
              .app-number-input
                height: 38px
                input
                  text-align: right
                  height: 100%
                  width: 90px

            .label
              padding: 7px
              font-size: 15px
</style>
